import React, { useEffect, useState } from 'react';
import './style.css';
import 'swiper/css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel } from '../../store/mainReducer';

const OnBoardingFirst = ({}) => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('onboarding1');
  useEffect(() => {
    setClassPage('onboarding1 onboarding1_active');
  }, []);
  function buttonHandler() {
    setClassPage('onboarding1');
    setTimeout(() => {     
      dispatch(setActivePanel("main"));
    }, 350)
  }
    return (
        <>
          <div className={`container ${classPage}`}>
            <img className='main__blue-bg' src="assets/images/onboarding-bg.png" alt="" />
            <img className='onboarding1__top-img' src="assets/images/onboarding-image.png" alt="" />
            <div className='onboarding1__content'>
              <p className='onboarding1__title'>
                Пора начинать игру
              </p>
              <p className='onboarding1__text'>
                Правила очень простые&nbsp;&mdash; просто вращай 
                Колесо Фортуны и&nbsp;смотри, какой подарок 
                тебе достанется.<br />
                Покажи результат сотруднику
                на&nbsp;нашем стенде, и&nbsp;он&nbsp;выдаст тебе твой приз.<br /><br />
                С&nbsp;тебя&nbsp;&mdash; крутить колесо,<br />
                с&nbsp;нас&nbsp;&mdash; подарки!
              </p>
              <button onClick={buttonHandler} className='onboarding1__button' type='button'>
                <p className='onboarding1__button-text'>
                  Вперёд!
                </p>
                <img className='onboarding1__arrow' src="assets/images/onboarding-arrow.svg" alt="" />
              </button>
            </div>
          </div>
        </>
    );
}

export default OnBoardingFirst;