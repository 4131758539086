import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setGift } from '../../store/mainReducer';
import ServerConnect from '../../service';
import { isIOS, isDesktop } from 'react-device-detect';


const Main = ({}) => {
  const dispatch = useDispatch();
  const currentPopup = useSelector((state) => state.main.currentPopup);
  const [classPage, setClassPage] = useState('main');
  const [isActiveArrow, setActiveArrow] = useState(!currentPopup.win);
  const [wheelRotate, setWheelRotate] = useState('');
  const [prizePopup, setPrizePopup] = useState(currentPopup.push_sent && !currentPopup.push_final_sent ? true : false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [finalPopup, setFinalPopup] = useState(currentPopup.push_final_sent ? true : false);
  const gift = useSelector((state) => state.main.gift);
  const items = useSelector((state) => state.main.prizes);
  const tgId = useSelector((state) => state.main.tgId);

  const filledItems = fillTo16Elements(items);
  
  function fillTo16Elements(dataFromBackend) {
    const result = [...dataFromBackend]; 
    const totalItems = 16; 
  
    let index = 0; 
    while (result.length < totalItems) {
      const currentItem = dataFromBackend[index];
      result.push({
        ...currentItem,
        id: dataFromBackend[index].id, 
        name: `${currentItem.name}`,
        description: `${currentItem.description}`
      });
      index = (index + 1) % dataFromBackend.length; 
    }
    console.log(result)
    return result;
  }

  function getRandomItemAndAngle(array, id) {
    const foundItem = array.find(item => item.id === id);
    const targetIndex = array.findIndex(item => item.id === id);
    const anglePerItem = 360 / array.length; 
    const rotationAngle = targetIndex * anglePerItem; 
    return {
      item: foundItem,
      angle: rotationAngle,
      randomIndex: targetIndex,
    };
  }

  function rotateHandler() {
    if (isActiveArrow) {

      window.Telegram.WebApp.HapticFeedback.notificationOccurred('success')
      setActiveArrow(false);
      ServerConnect.sendPrize('/prize/', tgId)
        .then((prize) => {
          console.log(prize);
          const {item, angle, randomIndex} = getRandomItemAndAngle(filledItems, prize.prize.type.id);
          console.log(randomIndex);
          setWheelRotate(`rotate(-${angle + 1440}deg)`);
          setTimeout(() => {
            ServerConnect.sendPrizePush('/send_prize_push/', tgId)
              .then(data => console.log(data))
              .catch(err => console.log(err));

            dispatch(setGift({
              name: item.name,
              description: item.description,
              id: item.id,
              index: randomIndex,
            }));
            setTimeout(() => {
              setPrizePopup(true);
            }, 1500);
          }, 5000);
        })
        .catch(err => console.log(err));
    }
  }

  useEffect(() => {
    setClassPage("main main_active");
  }, [])

  const angleStep = 360 / filledItems.length;

    return (
        <>
          <div className={`container ${classPage}`}>
            <div className={prizePopup ? 'prize-popup prize-popup_active' : 'prize-popup'}>
              <img className='prize-popup__top-bg' src="assets/images/prize-popup-top-bg.png" alt="" />
              <img className='prize-popup__bottom-bg' src="assets/images/prize-popup-bottom-bg.png" alt="" />
              <div className='prize-popup__content'>
                <img style={{width: gift.id == 3 ? '233px' : gift.id == 4 ? '125px' : gift.id == 5 ? '210px' : gift.id == 6 ? '240px' : gift.id == 7 ? '199px' : gift.id == 9 ? '196px'  : gift.id == 12 ? '200px' : '',transform: gift.id == 3 ? 'translateX(30px)' : '',marginTop: gift.id == 8 || gift.id == 10 || gift.id == 11 ? '10px' : gift.id == 12 ? '40px' : '0'}} className='prize-popup__img' src={`assets/images/prize-${gift.id}.png`} alt="" />
                <p className='prize-popup__plug'>Ваш подарок</p>
                <p className='prize-popup__title'>
                  {gift.name}
                </p>
                <p className='prize-popup__subtitle'>
                  {gift.description}
                </p>
                <p className='prize-popup__notification'>
                  Просто покажи это сотруднику стенда<br />
                  и получи подарок!
                </p>
                <div onClick={() => {
                  setPrizePopup(false);
                  setConfirmPopup(true);
                }} className='prize-popup__info'>
                  Я получил приз!
                </div>
              </div>
            </div>
            <div className={confirmPopup ? 'confirm-popup confirm-popup_active' : 'confirm-popup'}>
              <img className='confirm-popup__top-bg' src="assets/images/prize-popup-top-bg.png" alt="" />
              <img className='confirm-popup__bottom-bg' src="assets/images/prize-popup-bottom-bg.png" alt="" />
              <div className='confirm-popup__content'>
                <p className='confirm-popup__title'>
                  Вы&nbsp;получили приз?
                </p>
                <p className='confirm-popup__subtitle'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <button onClick={() => {
                  ServerConnect.sendFinalPush('/send_final_push/', tgId)
                    .then((data) => {
                      console.log(data);
                      setConfirmPopup(false);
                      setFinalPopup(true);
                    })
                    .catch(err => console.log(err));
                }} className='confirm-popup__btn confirm-popup__btn_submit' type='button'>
                  Подтвердить
                </button>
                <button onClick={() => {
                  setConfirmPopup(false);
                  setPrizePopup(true);
                }} className='confirm-popup__btn confirm-popup__btn_reject' type='button'>
                  Отменить
                </button>
              </div>
            </div>
            <div className={finalPopup ? 'final-popup final-popup_active' : 'final-popup'}>
              <img className='final-popup__top-bg' src="assets/images/prize-popup-top-bg.png" alt="" />
              <img className='final-popup__bottom-bg' src="assets/images/prize-popup-bottom-bg.png" alt="" />
              <div className='final-popup__content'>
                <p className='final-popup__title'>
                  {gift.name}
                </p>
                <p className='final-popup__subtitle'>
                  {gift.description}
                </p>
                <div className='final-popup__info'>
                  Приз уже получен
                </div>
              </div>
            </div>
            <img className='main__blue-bg' src="assets/images/blue-bg.png" alt="" />
            <img className='main__logo' src="assets/images/avito-main-logo.svg" alt="" />
            <img className='wheel__bg' src="assets/images/wheel-bg.png" alt="" />
            <img style={{pointerEvents: isActiveArrow ? 'all' : 'none', opacity: isActiveArrow ? '1' : '.3'}} onClick={rotateHandler} className='wheel__arrow' src="assets/images/wheel-arrow.png" alt="" />
            <div style={{transform: wheelRotate}} className='main__wheel wheel'>
                 {filledItems.map((item, index) => (
                    <div
                      key={index}
                      className="wheel__item"
                      style={{
                        transform: `rotate(${index * angleStep}deg) translate(0, -50px)`, // Угол и смещение
                      }}
                    >
                      <img
                        className="wheel__item-bg"
                        src={gift.index == index ? 'assets/images/triangle-blue.png' : "assets/images/traingle-white.png"}
                        alt=""
                      />
                      <p
                        className="wheel__item-text"
                        style={{
                          transform: `rotate(-90deg) translateX(5px)`, // Горизонтальное выравнивание текста
                          color: gift.index == index ? '#FFF' : ''  
                        }}
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
            </div>
          </div>
        </>
    );
}

export default Main;