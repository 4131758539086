import React,{ useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import Main from './components/Main';
import Loading from './components/Loading';

import { setActivePanel } from "./store/mainReducer"

import OnBoardingFirst from './components/OnBoardingFirst';

const App = () => {
  const dispatch = useDispatch();
  const activePanel = useSelector(state => state.main.activePanel);

  useEffect(() => {
    dispatch(setActivePanel('loading'));

    window.Telegram.WebApp.onEvent('viewportChanged', () => {
      window.scrollTo({
        top: document.body.scrollHeight,
      });
      setTimeout(() => {
        window.scrollTo({
          top: 0,
        });
      }, 0)
    })
  }, [])

  let content = []
  if (activePanel === 'loading') {
    content.push(<Loading key='loading'/>)
  }
  else if (activePanel === 'onboarding-first') {
    content.push(<OnBoardingFirst key='onboarding-first'/>)
  }
  else if (activePanel === 'main') {
    content.push(<Main key='main'/>)
  }
  return (
    <div className='app'>
      <>
        {content}
      </>
    </div>
  );
}
export default App;
