import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { setActivePanel, setCurrentPopup, setGift, setPrizes, setTgId } from "../../store/mainReducer";
import './style.css'
import ServerConnect from '../../service';

const Loading = ({}) => {
    const dispatch = useDispatch();
    const [classPage, setClassPage] = useState("loading-page")

    function parseQuery(queryString) {
      let query = {};
      let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
      for (let i = 0; i < pairs.length; i++) {
          let pair = pairs[i].split('=');
          query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
      return query;
    }
    let userChatId;

    useEffect(() => {
        let app = window.Telegram.WebApp;
        let query = app.initData;
        let user_data_str = parseQuery(query).user;
        let user_data = JSON.parse(user_data_str);
        app.disableVerticalSwipes();
        app.expand();
        app.ready();
        app.setHeaderColor("#d2eafd");
        app.setBackgroundColor("#d2eafd");
        app.setBottomBarColor("#d2eafd");
        userChatId = user_data["id"];

        dispatch(setTgId(userChatId));

        ServerConnect.getUser('/user/', userChatId)
          .then((data) => {
            console.log(data);
            dispatch(setCurrentPopup({
              win: data.win,
              push_final_sent: data.push_final_sent,
              push_sent: data.push_sent,
            }));
            if (data.win) {
              dispatch(setGift({
                name: data.prize.type.name,
                description: data.prize.type.description,
                id: data.prize.type.id,
                index: 0,
              }))
            }
            ServerConnect.getPrizes('/prize_types/', userChatId)
              .then((prizes) => {
                console.log(prizes);
                const sortedData = [...prizes.prize_types].sort((a, b) => a.id - b.id);
                dispatch(setPrizes(sortedData));
                setTimeout(() => {
                  if (data.win) {
                    setClassPage('loading-page loading-page_disable');
                    setTimeout(() => {
                      dispatch(setActivePanel("main"));
                    }, 350)
                  }
                  else {
                    setClassPage('loading-page loading-page_disable');
                    setTimeout(() => {
                      dispatch(setActivePanel("onboarding-first"));
                    }, 350)
                  }
                }, 2000);
              })
              .catch(err => console.log(err))
          })
          .catch(err => console.log(err));

        // Предзагрузка картинок
        const images = [
          "assets/images/avito-main-logo.svg",
          "assets/images/blue-bg.png",
          "assets/images/traingle-white.png",
          "assets/images/triangle-blue.png",
          "assets/images/wheel-arrow.png",
          "assets/images/wheel-bg.png",
          "assets/images/onboarding-arrow.svg",
          "assets/images/onboarding-bg.png",
          "assets/images/onboarding-image.png",
          "assets/images/prize-popup-bottom-bg.png",
          "assets/images/prize-popup-top-bg.png",
          "assets/images/prize-1.png",
          "assets/images/prize-2.png",
          "assets/images/prize-3.png",
          "assets/images/prize-4.png",
          "assets/images/prize-5.png",
          "assets/images/prize-6.png",
          "assets/images/prize-7.png",
          "assets/images/prize-8.png",
          "assets/images/prize-9.png",
          "assets/images/prize-10.png",
          "assets/images/prize-11.png",
          "assets/images/prize-12.png",
        ];
        const preload = () => {
          const promises = images.map((src) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = src;
              img.onload = resolve; // Успешная загрузка
              img.onerror = reject; // Ошибка загрузки
            });
          });
    
          Promise.all(promises)
            .then(() => {
              
            })
            .catch((error) => console.error("Error loading images:", error));
        };
        preload();


        return () => {
          window.scrollTo({
            top: document.body.scrollHeight,
          });
          setTimeout(() => {
            window.scrollTo({
              top: 0,
            });
          }, 0)
        }
    }, [])

    return (
        <div className={`container ${classPage}`}>
            <img className='main__blue-bg' src="assets/images/loading-bg.png" alt="" />
            <img className='loading__logo' src="assets/images/avito-main-logo.svg" alt="" />
            <img className='loading__bottom-image' src="assets/images/loading-bottom.png" alt="" />
            <p className='loading__text'>Загрузка<span className="dots"></span></p>
        </div>
    );
}


export default Loading;