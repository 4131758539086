import {createSlice, current} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        activePanel: '',
        gift: {
            name: '',
            description: '',
            id: '',
            index: '1000000000000'
        },
        tgId: '',
        prizes: [],
        currentPopup: {
            push_final_sent: false,
            push_sent: false,
            win: false,
        }
    },
    reducers: {
        setActivePanel(state, action) {
            state.activePanel = action.payload
        },
        setGift(state, action) {
            state.gift = action.payload;
        },
        setTgId(state, action) {
            state.tgId = action.payload;
        },
        setPrizes(state, action) {
            state.prizes = action.payload;
        },
        setCurrentPopup(state, action) {
            state.currentPopup = action.payload;
        }
    }
})

export default mainSlice.reducer
export const {
    setActivePanel,
    setGift,
    setTgId,
    setPrizes,
    setCurrentPopup,
} = mainSlice.actions